
















import {
  Component,
  Prop,
  Vue
} from "vue-property-decorator";

@Component({
  name: 'history-transfer',
})
export default class HistoryTransfer extends Vue {
  @Prop({}) private content!: string;

  private get parseContent(): any {
    return JSON.parse(this.content).appmsg;
  }

  private get isReceive(): boolean {
    return !(this.parseContent.wcpayinfo.paysubtype === 1)
  }
}
