

























import {
  Component,
  Model,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";

@Component({
  name: 'chat-voice-tip',
})
export default class ChatVoiceTip extends Vue {
  @Prop({}) private stateSay!: boolean
  @Prop({}) private sayCancel!: boolean

  @Watch('stateSay')
  private onChangeSay(val: boolean) {
    console.log('改变了')
  }
}
