












import { Component, Model, Vue } from "vue-property-decorator";

@Component({
    name: 'transport-chat',
})
export default class TransportChat extends Vue {
  @Model('change') isShow!: boolean;

  private handleClose() {
    this.$emit('change', false)
  }

  /**
   * @func 跳转到选择好友页面
   */
  private toTransport() {
    this.handleClose()
    this.$emit('jump')
  }
}
