









import {
  Component,
  Prop,
  Vue
} from "vue-property-decorator";

@Component({
  name: 'history-link',
})
export default class HistoryLink extends Vue {
  @Prop({}) private content!: string;

  private get parseContent(): any {
    return JSON.parse(this.content).appmsg;
  }

  private open() {
    if (this.parseContent.url.indexOf('http') === -1) {
      this.parseContent.url = 'http://' + this.parseContent.url
    }
    window.open(this.parseContent.url);
  }
}
