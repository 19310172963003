






import {
  Component,
  Prop,
  Vue
} from "vue-property-decorator";

@Component({
  name: 'history-location',
})
export default class HistoryLocation extends Vue {
  @Prop({}) private content!: string;

  private get parseContent(): any {
    return JSON.parse(this.content).location;
  }
}
