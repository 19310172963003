























































import {
  Component,
  Vue
} from "vue-property-decorator";
import {
  emojiMap
} from '@/utils/emoji-map'
import VoiceTips from './voice-tips.vue'
import GlobalQiniu from '@/components/global/qiniu.vue'
import {
  TaskMsgType
} from "@/utils/interface";
import {
  api
} from "@/api";
import {
  WechatModule
} from "@/store/modules/wechat";
import {
  ChatModule
} from "@/store/modules/chat";
import Recorder from 'js-audio-recorder'
const lamejs = require('lamejs')
import * as qiniu from 'qiniu-js'
import {
  appSource
} from "@/utils/func";
import bus from "@/utils/bus";

let recorder: any = null

@Component({
  name: 'chat-input',
  components: {
    VoiceTips,
    GlobalQiniu
  }
})
export default class ChatInput extends Vue {
  private message: string = ''
  private type: number | null = null
  private typeShow: number | null = null
  private isShowVoice: boolean = false
  private isShowEmoji: boolean = false
  private isShowMore: boolean = false
  private animationList: any = ['', 'ani-emoji', 'ani-more']
  private aniShowList: any = ['', 'ani-emoji-show', 'ani-more-show']
  private emojis: any = emojiMap
  private activeIndex = ''
  private startPos: number | undefined = 0;
  private endPos: number | undefined = 0;
  private fatherClass = ''
  private stateSay: boolean = false
  private sayCancel: boolean = false
  private messageType: number = 0
  private picUrl: string = ''
  private videoUrl: string = ''
  private moveStart: number = 0
  private formVoice: any = {
    data_src: '',
    data_type: 0,
    voice_length: 0 // 毫秒
  }
  private isSendVoice: boolean = false // 判断语音是否发送完成
  private isFocus: boolean = false // input输入框是否聚焦
  private sendWay: string = ''
  private recorderTimeInterval: any = null
  private isSendMsg: boolean = false

  private posStart = 0 // 初始化起点坐标
  private posEnd = 0 // 初始化终点坐标
  private sucTaskId = 0 // 发送成功后的任务id

  private get wxId(): number {
    return WechatModule.wxId
  }

  private get wxStrId(): string {
    return WechatModule.wxStrId
  }

  private get friendId(): number {
    return WechatModule.friendId
  }

  private get friendStrId(): string {
    return WechatModule.friendStrId
  }

  private get isChatRoom(): boolean {
    return WechatModule.isChatRoom
  }

  private get wxInfo(): any {
    return WechatModule.currentDeviceInfo ? JSON.parse(WechatModule.currentDeviceInfo) : ''
  }

  private get currentFriend(): any {
    return WechatModule.currentFriInfo ? JSON.parse(WechatModule.currentFriInfo) : ''
  }

  private get sendMsgTaskId(): number[] {
    return ChatModule.sendMsgTaskId
  }

  private mounted() {
    bus.$on('AiteSomeOne', this.aiTeSomeOne)
  }

  private handlerOper(type: number) {
    if (type === this.type) {
      this.type = null
      this.fatherClass = ''
      this.isShowVoice = false
      recorder.destroy()
      setTimeout(() => {
        this.isShowEmoji = false
        this.isShowMore = false
      }, 500)
      return false
    }
    this.type = type
    if (type === 1) {
      this.isShowEmoji = true
      this.isShowMore = false
      this.isShowVoice = false
    } else if (type == 2) {
      this.isShowMore = true
      this.isShowEmoji = false
      this.isShowVoice = false
    } else {
      this.isShowVoice = true;
      (Recorder as any).getPermission().then(() => {})
    }
    setTimeout(() => {
      this.fatherClass = this.aniShowList[type]
    }, 200)
  }

  /**
   * 监听关闭
   */
  private onClose() {
    this.type = null
    this.fatherClass = ''
    if (!this.sendWay) {
      this.isFocus = false
    }
    setTimeout(() => {
      this.isShowEmoji = false
      this.isShowMore = false
    }, 500)
  }

  /**
   * @func 点击表情包
   */
  private selectEmoji(data: any) {
    this.activeIndex = data.tips
    this.append(data.code)
    setTimeout(() => {
      this.activeIndex = ''
    }, 500)
  }

  /**
   * @func 点击emoji
   */
  private append(emoji: any) {
    if (this.startPos === undefined || this.endPos === undefined) return;
    const refs: any = this.$refs.focusTextarea
    if (refs) {
      refs.focus()
    }
    this.message = this.message.substring(0, this.startPos) + emoji + this.message.substring(this
      .endPos);
    this.startPos += (emoji.length);
    this.endPos += (emoji.length);
  }

  /**
   * @func 发送消息
   */
  private send(type ? : string) {
    if (type === 'enter') {
      this.sendWay = 'enter'
    } else {
      this.sendWay = ''
    }
    if (this.message.trim() === '') {
      this.$weui.topTips('内容不能为空')
      return
    }
    this.assembleMsgData(0);
  }

  /**
   * @func 发送图片
   * @param url 图片路径
   */
  private sendPic(url: string) {
    this.picUrl = url
    this.assembleMsgData(1)
  }

  /**
   * @func 发送视频
   * @param url 图片路径
   */
  private sendVideo(url: string) {
    this.videoUrl = url
    this.assembleMsgData(3)
  }

  /**
   * @func 发送小程序
   */
  private sendGroup() {
    this.$router.push({
      name: 'Chatroom',
      query: {
        type: '1'
      }
    })
  }

  /**
   * @func 发送公众号
   */
  private async sendPublic() {
    try {
      const res: any = await api.sendMpQrcode({
        wxid: this.wxId,
        friend_id: this.friendId,
        state: 0 // 0 礼盒机
      })
      this.picUrl = res.qrcode_url
      this.assembleMsgData(1)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @func 组装发送消息
   * @param type 消息类型
   */
  private assembleMsgData(type ? : number) {
    if (this.isSendMsg) return
    this.isSendMsg = true
    let content: string = ''
    const param: any = {
      task_time: 0,
      task_single_msg: {}
    }
    if (!type) {
      param.task_single_msg = {
        task_type: TaskMsgType.msg_send_text,
        content: this.message
      }
      content = this.message
      
      this.messageType = TaskMsgType.msg_send_text
    }
    if (type === TaskMsgType.msg_send_image) {
      param.task_single_msg = {
        task_type: TaskMsgType.msg_send_image,
        content: this.picUrl
      }
      content = this.picUrl;
      this.messageType = TaskMsgType.msg_send_image
    }
    if (type === TaskMsgType.msg_send_video) {
      param.task_single_msg = {
        task_type: TaskMsgType.msg_send_video,
        content: this.videoUrl
      }
      content = this.videoUrl
      this.messageType = TaskMsgType.msg_send_video
    }
    this.sendMsg(param, content)
  }

  /**
   * @func 请求发送消息
   * @param data 请求数据
   * @param content 发送消息
   */
  private async sendMsg(data: any, content: any) {
    try {
      const res: any = await api.sendSingleMessage({
        wechat_id: this.wxId,
        task: data,
        friend_id: this.friendId,
        friend_type: +this.isChatRoom
      })

      let ids = this.sendMsgTaskId
      ids.push(res.task_id)
      this.sucTaskId = res.task_id
      ChatModule.changeMsgTaskId(ids)
      this.$emit('sendTask', {id: res.task_id})
      this.sendShowData(content)
    } catch (err) {
      this.isSendMsg = false
      console.log(err)
    }
  }

  /**
   * @func 组装展示的发送数据
   */
  private sendShowData(content: string, type ? : number, time ? : number) {
    const msgType: any = [1, 2, 3, 5, 8, 21]
    const sendMessage: any = {
      wechat_id: this.wxId,
      msg_type: type || msgType[this.messageType],
      from_username: this.wxStrId,
      to_username: this.friendStrId,
      content: content,
      from_nick_name: this.wxInfo.name,
      from_avatar: this.wxInfo.avatar,
      to_nick_name: this.currentFriend.nick_name,
      to_avatar: this.currentFriend.head_url,
      record_time: parseInt(new Date().getTime() / 1000 + '', 10),
      voice_time: time || 0,
      suc_task_id: this.sucTaskId
    }
    this.sucTaskId = 0
    this.message = ''
    this.isSendMsg = false
    this.$emit('sendMessage', {
      msg: sendMessage
    });
    this.onClose()
  }

  /*------------- */

  /**
   * @func 获取七牛token
   */
  private async getToken(file: any) {
    try {
      const res: any = await api.getQiniuToken({})
      this.uploadRadio(res.token, file)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @func 开始录音
   */
  private startRecorder() {
    if (!recorder) {
      recorder = new Recorder()
    }
    recorder.start()
    this.recorderTimeInterval = setTimeout(() => {
      this.stateSay = false
      this.sayCancel = false
      this.isSendVoice = true
      this.stopPlayRecorder()
      setTimeout(() => {
        this.getMp3Data()
      }, 100)
    }, 60 * 1000 + 500)
  }

  // 停止录音
  private stopPlayRecorder() {
    console.log('停止录音')
    recorder.stop();
  }

  /**
   * @func 文件格式转换 mav-mp3
   */
  private getMp3Data() {
    const mp3Blob = this.convertToMp3(recorder.getWAV());
    this.getToken(mp3Blob)
  }

  private convertToMp3(wavDataView: any) {
    // 获取wav头信息
    const wav = lamejs.WavHeader.readHeader(wavDataView); // 此处其实可以不用去读wav头信息，毕竟有对应的config配置
    const {
      channels,
      sampleRate
    } = wav;
    const mp3enc = new lamejs.Mp3Encoder(channels, sampleRate, 128);
    // 获取左右通道数据
    const result = recorder.getChannelData()
    const buffer = [];
    const leftData = result.left && new Int16Array(result.left.buffer, 0, result.left.byteLength / 2);
    const rightData = result.right && new Int16Array(result.right.buffer, 0, result.right.byteLength / 2);
    const remaining = leftData.length + (rightData ? rightData.length : 0);
    const maxSamples = 1152;
    for (let i = 0; i < remaining; i += maxSamples) {
      const left = leftData.subarray(i, i + maxSamples);
      let right = null;
      let mp3buf = null;
      if (channels === 2) {
        right = rightData.subarray(i, i + maxSamples);
        mp3buf = mp3enc.encodeBuffer(left, right);
      } else {
        mp3buf = mp3enc.encodeBuffer(left);
      }
      if (mp3buf.length > 0) {
        buffer.push(mp3buf);
      }
    }
    const enc = mp3enc.flush();
    if (enc.length > 0) {
      buffer.push(enc);
    }
    return new Blob(buffer, {
      type: 'audio/mp3'
    });
  }

  /**
   * @func 上传语音
   */
  private uploadRadio(token: string, file: any) {
    const domain = "https://oss.guojiangmedia.com/"
    const nowTime = new Date().getTime()
    const fileKey = nowTime + Math.round(Math.random() * 10000) + '.mp3'
    const config: any = {
      useCdnDomain: true,
      region: qiniu.region.z0
    }

    const putExtra: any = {
      fname: '',
      params: {},
      mimeType: ''
    }

    let observable = qiniu.upload(file, fileKey, token, putExtra, config);
    observable.subscribe({
      next: (result) => {
        console.log(result)
      },
      error: (errResult) => {
        this.isSendVoice = false
        this.$weui.topTips('提交失败')
      },
      complete: (result) => {
        this.formVoice.data_src = domain + result.key
        this.formVoice.voice_length = recorder.duration
        this.sendVoice()
      }
    })
  }

  /**
   * @func 发送语音
   */
  private async sendVoice() {
    if (parseInt(this.formVoice.voice_length) * 1000 < 1000) {
      this.$weui.topTips('说话时间太短了')
      this.isSendVoice = false
      return
    }
    try {
      const res: any = await api.sendVoice({
        wechat_id: this.wxId,
        wxid: this.friendStrId,
        content: this.formVoice.data_src,
        voice_time: parseInt(this.formVoice.voice_length) * 1000
      })
      let ids = this.sendMsgTaskId
      ids.push(res.task_id)
      ChatModule.changeMsgTaskId(ids)
      this.$emit('sendTask', {id: res.task_id})
      this.messageType = 2
      this.sendShowData(this.formVoice.data_src, 0, parseInt(this.formVoice.voice_length) * 1000)
      this.isSendVoice = false

      // use 
      recorder.destroy()
      recorder = null
      // --- end ---
    } catch (err) {
      console.log(err)
      this.isSendVoice = false
    }
  }

  /**
   * @func 滚动到底部
   */
  private scrollToBottom() {
    if (appSource() === 'ios') {
      let scrollBox: any = document.getElementById('scrollBox')
      scrollBox.scrollTop = 0
    } else if (appSource() === 'andriod') {
      let scrollBox: any = document.getElementById('msg_end')
      scrollBox.scrollIntoView()
    }
  }

  /**
   * @func 监听input聚焦事件 将聊天记录滚动到最底部
   */
  private handleFocus() {
    this.scrollToBottom()
    this.isFocus = true
    this.type = null
    this.fatherClass = ''
    setTimeout(() => {
      this.isShowEmoji = false
      this.isShowMore = false
    }, 500)
  }

  /**
   * @func 监听语音按钮移动 touch-start
   */
  private handleTouchStart(event: any) {
    event.preventDefault()
    if (this.isSendVoice) return
    this.posStart = 0
    this.posStart = event.touches[0].pageY
    this.stateSay = true
    this.startRecorder()
  }

  /**
   * @func 监听语音按钮移动 touch-move
   */
  private handleTouchMove(event: any) {
    event.preventDefault()
    let posMove = event.changedTouches[0].pageY
    if (this.posStart - posMove > 58) {
      this.sayCancel = true
    } else {
      this.sayCancel = false
    }
  }

  /**
   * @func 监听语音按钮移动 touch-end
   */
  private handleTouchEnd(event: any) {
    event.preventDefault()
    this.posEnd = 0
    this.stateSay = false
    this.sayCancel = false
    this.posEnd = event.changedTouches[0].pageY
    if (this.posStart - this.posEnd > 58) {
      recorder.destroy()
      recorder = null
      clearTimeout(this.recorderTimeInterval)
      this.recorderTimeInterval = null
    } else {
      if (this.isSendVoice) return
      clearTimeout(this.recorderTimeInterval)
      this.recorderTimeInterval = null
      this.stopPlayRecorder()
      this.isSendVoice = true
      setTimeout(() => {
        this.getMp3Data()
      }, 100)
    }
  }

  /**
   * @func 获取艾特的用户昵称
   * @param nickname 用户昵称
   */
  private aiTeSomeOne(nickname: string) {
    if (this.message.indexOf(nickname) != -1) return
    this.message += nickname
    this.onFocus()
  }

  /**
   * @func 控制输入框聚焦
   */
  private onFocus() {
    const refs: any = this.$refs.inputBox
    this.$nextTick(() => {
      refs.focus()
    })
  }
}
