import { render, staticRenderFns } from "./chat-input.vue?vue&type=template&id=7478e4ed&scoped=true&"
import script from "./chat-input.vue?vue&type=script&lang=ts&"
export * from "./chat-input.vue?vue&type=script&lang=ts&"
import style0 from "./chat-input.vue?vue&type=style&index=0&id=7478e4ed&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7478e4ed",
  null
  
)

export default component.exports