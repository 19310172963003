const picHost = 'https://oss.guojiangmedia.com/'

export var emojiMap = [{
  tips: '微笑',
  icon: picHost + 'wehcat/emoji/[微笑].png',
  code: '[微笑]'
},{
  tips: '撇嘴',
  icon: picHost + 'wehcat/emoji/[撇嘴].png',
  code: '[撇嘴]'
},{
  tips: '色',
  icon: picHost + 'wehcat/emoji/[色].png',
  code: '[色]'
},{
  tips: '发呆',
  icon: picHost + 'wehcat/emoji/[发呆].png',
  code: '[发呆]'
},{
  tips: '得意',
  icon: picHost + 'wehcat/emoji/[得意].png',
  code: '[得意]'
},{
  tips: '流泪',
  icon: picHost + 'wehcat/emoji/[流泪].png',
  code: '[流泪]'
},{
  tips: '害羞',
  icon: picHost + 'wehcat/emoji/[害羞].png',
  code: '[害羞]'
},{
  tips: '闭嘴',
  icon: picHost + 'wehcat/emoji/[闭嘴].png',
  code: '[闭嘴]'
},{
  tips: '睡',
  icon: picHost + 'wehcat/emoji/[睡].png',
  code: '[睡]'
},{
  tips: '666',
  icon: picHost + 'wehcat/emoji/[666].png',
  code: '[666]'
},{
  tips: 'Emm',
  icon: picHost + 'wehcat/emoji/[Emm].png',
  code: '[Emm]'
},{
  tips: 'OK',
  icon: picHost + 'wehcat/emoji/[OK].png',
  code: '[OK]'
},{
  tips: '裂开',
  icon: picHost + 'wehcat/emoji/[裂开].png',
  code: '[裂开]'
},{
  tips: '爱心',
  icon: picHost + 'wehcat/emoji/[爱心].png',
  code: '[爱心]'
},{
  tips: '傲慢',
  icon: picHost + 'wehcat/emoji/[傲慢].png',
  code: '[傲慢]'
},{
  tips: '白眼',
  icon: picHost + 'wehcat/emoji/[白眼].png',
  code: '[白眼]'
},{
  tips: '抱拳',
  icon: picHost + 'wehcat/emoji/[抱拳].png',
  code: '[抱拳]'
},{
  tips: '爆竹',
  icon: picHost + 'wehcat/emoji/[爆竹].png',
  code: '[爆竹]'
},{
  tips: '鄙视',
  icon: picHost + 'wehcat/emoji/[鄙视].png',
  code: '[鄙视]'
},{
  tips: '便便',
  icon: picHost + 'wehcat/emoji/[便便].png',
  code: '[便便]'
},{
  tips: '擦汗',
  icon: picHost + 'wehcat/emoji/[擦汗].png',
  code: '[擦汗]'
},{
  tips: '菜刀',
  icon: picHost + 'wehcat/emoji/[菜刀].png',
  code: '[菜刀]'
},{
  tips: '吃瓜',
  icon: picHost + 'wehcat/emoji/[吃瓜].png',
  code: '[吃瓜]'
},{
  tips: '呲牙',
  icon: picHost + 'wehcat/emoji/[呲牙].png',
  code: '[呲牙]'
},{
  tips: '打脸',
  icon: picHost + 'wehcat/emoji/[打脸].png',
  code: '[打脸]'
},{
  tips: '大哭',
  icon: picHost + 'wehcat/emoji/[大哭].png',
  code: '[大哭]'
},{
  tips: '蛋糕',
  icon: picHost + 'wehcat/emoji/[蛋糕].png',
  code: '[蛋糕]'
},{
  tips: '凋谢',
  icon: picHost + 'wehcat/emoji/[凋谢].png',
  code: '[凋谢]'
},{
  tips: '调皮',
  icon: picHost + 'wehcat/emoji/[调皮].png',
  code: '[调皮]'
},{
  tips: '发抖',
  icon: picHost + 'wehcat/emoji/[发抖].png',
  code: '[发抖]'
},{
  tips: '发怒',
  icon: picHost + 'wehcat/emoji/[发怒].png',
  code: '[发怒]'
},{
  tips: '發',
  icon: picHost + 'wehcat/emoji/[發].png',
  code: '[發]'
},{
  tips: '翻白眼',
  icon: picHost + 'wehcat/emoji/[翻白眼].png',
  code: '[翻白眼]'
},{
  tips: '福',
  icon: picHost + 'wehcat/emoji/[福].png',
  code: '[福]'
},{
  tips: '尴尬',
  icon: picHost + 'wehcat/emoji/[尴尬].png',
  code: '[尴尬]'
},{
  tips: '勾引',
  icon: picHost + 'wehcat/emoji/[勾引].png',
  code: '[勾引]'
},{
  tips: '鼓掌',
  icon: picHost + 'wehcat/emoji/[鼓掌].png',
  code: '[鼓掌]'
},{
  tips: '憨笑',
  icon: picHost + 'wehcat/emoji/[憨笑].png',
  code: '[憨笑]'
},{
  tips: '汗',
  icon: picHost + 'wehcat/emoji/[汗].png',
  code: '[汗]'
},{
  tips: '好的',
  icon: picHost + 'wehcat/emoji/[好的].png',
  code: '[好的]'
},{
  tips: '合十',
  icon: picHost + 'wehcat/emoji/[合十].png',
  code: '[合十]'
},{
  tips: '嘿哈',
  icon: picHost + 'wehcat/emoji/[嘿哈].png',
  code: '[嘿哈]'
},{
  tips: '红包',
  icon: picHost + 'wehcat/emoji/[红包].png',
  code: '[红包]'
},{
  tips: '坏笑',
  icon: picHost + 'wehcat/emoji/[坏笑].png',
  code: '[坏笑]'
},{
  tips: '机智',
  icon: picHost + 'wehcat/emoji/[机智].png',
  code: '[机智]'
},{
  tips: '加油',
  icon: picHost + 'wehcat/emoji/[加油].png',
  code: '[加油]'
},{
  tips: '奸笑',
  icon: picHost + 'wehcat/emoji/[奸笑].png',
  code: '[奸笑]'
},{
  tips: '惊恐',
  icon: picHost + 'wehcat/emoji/[惊恐].png',
  code: '[惊恐]'
},{
  tips: '囧',
  icon: picHost + 'wehcat/emoji/[囧].png',
  code: '[囧]'
},{
  tips: '咖啡',
  icon: picHost + 'wehcat/emoji/[咖啡].png',
  code: '[咖啡]'
},{
  tips: '可怜',
  icon: picHost + 'wehcat/emoji/[可怜].png',
  code: '[可怜]'
},{
  tips: '恐惧',
  icon: picHost + 'wehcat/emoji/[恐惧].png',
  code: '[恐惧]'
},{
  tips: '抠鼻',
  icon: picHost + 'wehcat/emoji/[抠鼻].png',
  code: '[抠鼻]'
},{
  tips: '骷髅',
  icon: picHost + 'wehcat/emoji/[骷髅].png',
  code: '[骷髅]'
},{
  tips: '苦涩',
  icon: picHost + 'wehcat/emoji/[苦涩].png',
  code: '[苦涩]'
},{
  tips: '快哭了',
  icon: picHost + 'wehcat/emoji/[快哭了].png',
  code: '[快哭了]'
},{
  tips: '困',
  icon: picHost + 'wehcat/emoji/[困].png',
  code: '[困]'
},{
  tips: '礼物',
  icon: picHost + 'wehcat/emoji/[礼物].png',
  code: '[礼物]'
},{
  tips: '脸红',
  icon: picHost + 'wehcat/emoji/[脸红].png',
  code: '[脸红]'
},{
  tips: '玫瑰',
  icon: picHost + 'wehcat/emoji/[玫瑰].png',
  code: '[玫瑰]'
},{
  tips: '难过',
  icon: picHost + 'wehcat/emoji/[难过].png',
  code: '[难过]'
},{
  tips: '啤酒',
  icon: picHost + 'wehcat/emoji/[啤酒].png',
  code: '[啤酒]'
},{
  tips: '破涕为笑',
  icon: picHost + 'wehcat/emoji/[破涕为笑].png',
  code: '[破涕为笑]'
},{
  tips: '强',
  icon: picHost + 'wehcat/emoji/[强].png',
  code: '[强]'
},{
  tips: '敲打',
  icon: picHost + 'wehcat/emoji/[敲打].png',
  code: '[敲打]'
},{
  tips: '亲亲',
  icon: picHost + 'wehcat/emoji/[亲亲].png',
  code: '[亲亲]'
},{
  tips: '庆祝',
  icon: picHost + 'wehcat/emoji/[庆祝].png',
  code: '[庆祝]'
},{
  tips: '拳头',
  icon: picHost + 'wehcat/emoji/[拳头].png',
  code: '[拳头]'
},{
  tips: '让我看看',
  icon: picHost + 'wehcat/emoji/[让我看看].png',
  code: '[让我看看]'
},{
  tips: '弱',
  icon: picHost + 'wehcat/emoji/[弱].png',
  code: '[弱]'
},{
  tips: '社会社会',
  icon: picHost + 'wehcat/emoji/[社会社会].png',
  code: '[社会社会]'
},{
  tips: '生病',
  icon: picHost + 'wehcat/emoji/[生病].png',
  code: '[生病]'
},{
  tips: '胜利',
  icon: picHost + 'wehcat/emoji/[胜利].png',
  code: '[胜利]'
},{
  tips: '失望',
  icon: picHost + 'wehcat/emoji/[失望].png',
  code: '[失望]'
},{
  tips: '衰',
  icon: picHost + 'wehcat/emoji/[衰].png',
  code: '[衰]'
},{
  tips: '太阳',
  icon: picHost + 'wehcat/emoji/[太阳].png',
  code: '[太阳]'
},{
  tips: '叹气',
  icon: picHost + 'wehcat/emoji/[叹气].png',
  code: '[叹气]'
},{
  tips: '天啊',
  icon: picHost + 'wehcat/emoji/[天啊].png',
  code: '[天啊]'
},{
  tips: '跳跳',
  icon: picHost + 'wehcat/emoji/[跳跳].png',
  code: '[跳跳]'
},{
  tips: '偷笑',
  icon: picHost + 'wehcat/emoji/[偷笑].png',
  code: '[偷笑]'
},{
  tips: '吐',
  icon: picHost + 'wehcat/emoji/[吐].png',
  code: '[吐]'
},{
  tips: '哇',
  icon: picHost + 'wehcat/emoji/[哇].png',
  code: '[哇]'
},{
  tips: '旺柴',
  icon: picHost + 'wehcat/emoji/[旺柴].png',
  code: '[旺柴]'
},{
  tips: '委屈',
  icon: picHost + 'wehcat/emoji/[委屈].png',
  code: '[委屈]'
},{
  tips: '握手',
  icon: picHost + 'wehcat/emoji/[握手].png',
  code: '[握手]'
},{
  tips: '无语',
  icon: picHost + 'wehcat/emoji/[无语].png',
  code: '[无语]'
},{
  tips: '捂脸',
  icon: picHost + 'wehcat/emoji/[捂脸].png',
  code: '[捂脸]'
},{
  tips: '笑脸',
  icon: picHost + 'wehcat/emoji/[笑脸].png',
  code: '[笑脸]'
},{
  tips: '心碎',
  icon: picHost + 'wehcat/emoji/[心碎].png',
  code: '[心碎]'
},{
  tips: '嘘',
  icon: picHost + 'wehcat/emoji/[嘘].png',
  code: '[嘘]'
},{
  tips: '烟花',
  icon: picHost + 'wehcat/emoji/[烟花].png',
  code: '[烟花]'
},{
  tips: '耶',
  icon: picHost + 'wehcat/emoji/[耶].png',
  code: '[耶]'
},{
  tips: '疑问',
  icon: picHost + 'wehcat/emoji/[疑问].png',
  code: '[疑问]'
},{
  tips: '阴险',
  icon: picHost + 'wehcat/emoji/[阴险].png',
  code: '[阴险]'
},{
  tips: '拥抱',
  icon: picHost + 'wehcat/emoji/[拥抱].png',
  code: '[拥抱]'
},{
  tips: '悠闲',
  icon: picHost + 'wehcat/emoji/[悠闲].png',
  code: '[悠闲]'
},{
  tips: '右哼哼',
  icon: picHost + 'wehcat/emoji/[右哼哼].png',
  code: '[右哼哼]'
},{
  tips: '愉快',
  icon: picHost + 'wehcat/emoji/[愉快].png',
  code: '[愉快]'
},{
  tips: '月亮',
  icon: picHost + 'wehcat/emoji/[月亮].png',
  code: '[月亮]'
},{
  tips: '晕',
  icon: picHost + 'wehcat/emoji/[晕].png',
  code: '[晕]'
},{
  tips: '再见',
  icon: picHost + 'wehcat/emoji/[再见].png',
  code: '[再见]'
},{
  tips: '炸弹',
  icon: picHost + 'wehcat/emoji/[炸弹].png',
  code: '[炸弹]'
},{
  tips: '咒骂',
  icon: picHost + 'wehcat/emoji/[咒骂].png',
  code: '[咒骂]'
},{
  tips: '皱眉',
  icon: picHost + 'wehcat/emoji/[皱眉].png',
  code: '[皱眉]'
},{
  tips: '猪头',
  icon: picHost + 'wehcat/emoji/[猪头].png',
  code: '[猪头]'
},{
  tips: '抓狂',
  icon: picHost + 'wehcat/emoji/[抓狂].png',
  code: '[抓狂]'
},{
  tips: '转圈',
  icon: picHost + 'wehcat/emoji/[转圈].png',
  code: '[转圈]'
},{
  tips: '嘴唇',
  icon: picHost + 'wehcat/emoji/[嘴唇].png',
  code: '[嘴唇]'
}];