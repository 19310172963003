











import {
  Component,
  Prop,
  Vue
} from "vue-property-decorator";

@Component({
  name: 'history-card',
})
export default class HistoryCard extends Vue {
  @Prop({}) private content!: string;

  private get parseContent() {
    return JSON.parse(this.content)
  }
}
