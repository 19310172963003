











import {
  replaceToHttps
} from "@/utils/func";
import {
  Component,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";

@Component({
  name: 'history-video',
})
export default class HistoryVideo extends Vue {
  @Prop({}) private content!: string
  @Prop({}) private id!: number
  private videoUrl: string = ''
  private isFullScreen: boolean = true
  private isPlay: boolean = false

  private video: any = {
    url: '',
    cover: replaceToHttps(this.content) + '?vframe/jpg/offset/0',
    muted: false,
    loop: false,
    preload: 'auto',
    poster: '',
    volume: 1,
    autoplay: false
  }

  @Watch('content', {
    immediate: true,
    deep: false
  })
  private onChangeContent(val: string, oldVal: string) {
    this.videoUrl = replaceToHttps(this.content)
    this.video.url = replaceToHttps(this.content)
  }

  private get $video() {
    const refs: any = this.$refs['vueMiniPlayer_' + this.id]
    return refs.$video
  }

  private videoPlay() {
    this.isPlay = true
    const refs: any = this.$refs['vueMiniPlayer_' + this.id]
    if (refs) {
      refs.play()
    }
  }

  private videoPause() {
    this.isPlay = false
    const refs: any = this.$refs['vueMiniPlayer_' + this.id]
    if (refs) {
      refs.pause()
    }
  }

  private clickVideo() {
    if (this.isPlay) {
      this.videoPause()
    } else {
      this.videoPlay()
    }
  }
}
