
































































































































import {
  Component,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator"
import {
  clearNullArr,
  copyText,
  parseTime
} from '@/utils/func'
import {
  WechatModule
} from "@/store/modules/wechat"
import {
  onScroll
} from '@/utils/func'
import HistoryVoice from '@/components/chat/history-voice.vue'
import HistoryCard from '@/components/chat/history-card.vue'
import HistoryLocation from '@/components/chat/history-location.vue'
import HistoryLink from '@/components/chat/history-link.vue'
import HistoryHb from '@/components/chat/history-hb.vue'
import HistoryTransfer from '@/components/chat/history-transfer.vue'
import HistoryMiniProgram from '@/components/chat/history-mini-program.vue'
import HistoryFile from '@/components/chat/history-file.vue'
import HistoryVideo from '@/components/chat/history-video.vue'
import TransportChat from './transport-chat.vue'
import {
  ChatModule
} from "@/store/modules/chat"
import {
  api
} from "@/api"
import {
  TaskMsgType
} from "@/utils/interface"
import bus from "@/utils/bus"

@Component({
  name: 'chat-history',
  components: {
    HistoryVoice,
    HistoryCard,
    HistoryLocation,
    HistoryLink,
    HistoryHb,
    HistoryTransfer,
    HistoryMiniProgram,
    HistoryFile,
    HistoryVideo,
    TransportChat
  },
  filters: {
    tranTime(val: any) {
      return parseTime(val, '{y}/{m}/{d} {h}:{i}:{s}')
    },
    transformContent(val: any) {
      return val.replace(/<[^>]+>/g, "")
    }
  }
})
export default class ChatHistory extends Vue {
  @Prop({
    required: true
  }) private list!: any;
  @Prop({}) private hasMore!: boolean;
  private endIndex: number = 0;
  private lastIndex = 0;
  private loading: boolean = false;
  private chatMsgType = {
    msg_type_none: 0,
    msg_type_text: 1,
    msg_type_img: 2,
    msg_type_voice: 3,
    msg_type_card: 4,
    msg_type_video: 5,
    msg_type_emoji: 6,
    msg_type_location: 7,
    msg_type_link: 8,
    msg_type_hb: 9, // 红包
    msg_type_tp: 10, // 转账
    msg_type_share_location: 11, // 共享位置
    msg_type_voip_voice: 12, // 语音通话
    msg_type_voip_video: 13, // 视频通话
    msg_type_card_item: 14, // 卡券
    msg_type_file: 15, // 文件
    msg_type_weapp: 16, // 小程序
    msg_type_msg_forward: 17, // 聊天记录转发
    msg_type_gif: 21 // gif
  };
  private isSelectState = false // 是否是选择会话状态
  private selectMsgMap: any = {}
  private selectMsgIds: number[] = []
  private defaultAvatar = 'https://oss.guojiangmedia.com/wxbackend/default-avatar.png'
  private menuIndex = 0 // 长按选中的内容id
  private menuData: any = null
  private longPressLoop: any = null

  private get wxId(): number {
    return WechatModule.wxId
  }

  private get friendId(): number {
    return WechatModule.friendId
  }

  private get friendStrId(): string {
    return WechatModule.friendStrId
  }

  private get wxStrId(): string {
    return WechatModule.wxStrId
  }

  private get isChatRoom(): boolean {
    return WechatModule.isChatRoom
  }

  @Watch('friendId', {
    immediate: true
  })
  private onFriendId(val: number) {
    setTimeout(() => {
      var div: any = document.getElementById('scrollBox')
      div.scrollTop = div.scrollHeight
    }, 100)
  }

  /**
   * @func 是否是自己发
   */
  private isSelf(val: any) {
    if (val.chatroom_member) {
      return val.chatroom_member == this.wxStrId
    } else {
      return val.from_username == this.wxStrId
    }
  }

  private listenScroll(e: any) {
    onScroll({
      e: e,
      scrollDown: () => {
        if (!this.hasMore) return
        if (this.loading) return
        this.loading = true
        this.$emit('scroll-top', this.topDone)
      }
    })
  }

  /**
   * @func 滚动loading
   */
  private topDone() {
    this.loading = false
  }

  /**
   * @func 长按聊天记录
   */
  private handlePress(e: any, data: any) {
    // e.preventDefault()
    this.menuIndex = data.id
    this.menuData = data
  }

  /**
   * @func 关闭右键菜单
   */
  private closeMenu() {
    this.menuIndex = 0
    this.menuData = null
  }

  /**
   * @func 多选操作
   */
  private shwoMoreSelect() {
    this.isSelectState = true
    this.closeMenu()
  }

  /**
   * @func 复制内容操作
   */
  private copyText() {
    copyText(this.menuData.content, () => {
      this.$weui.topTips('复制成功', {
        className: 'success-tips'
      })
      this.closeMenu()
    })
  }

  /**
   * @func 点击选择聊天内容
   * @param msg 
   */
  private selectMsg(msg: any) {
    if (!this.isSelectState) return
    if (this.selectMsgIds.indexOf(msg.id) !== -1) {
      let index = this.selectMsgIds.indexOf(msg.id)
      this.selectMsgIds.splice(index, 1)
      delete this.selectMsgMap[msg.id]
      this.selectMsgMap = clearNullArr(this.selectMsgMap)
    } else {
      this.selectMsgIds.push(msg.id)
      this.selectMsgMap[msg.id] = msg
    }

    this.$emit('select', this.selectMsgMap)
  }

  /**
   * @func 取消转发操作
   * @param bool 是否取消
   */
  private cancelSelect(bool: boolean) {
    if (!bool) {
      this.isSelectState = false
      this.selectMsgIds = []
      this.selectMsgMap = {}
    }
  }

  /**
   * @func 跳转到选择好友页面
   */
  private toTransport() {
    if (!this.selectMsgIds.length) {
      this.$weui.topTips('转发内容不能为空')
      return
    }
    ChatModule.changeSelectChatList(this.selectMsgMap)
    this.$router.push({
      name: 'SelectDialogue'
    })
  }

  /**
   * @func 点击头像去私聊
   * @param 好友信息
   */
  private async toChatAlone(data: any) {
    if (!this.isChatRoom) {
      this.getFriendDetail(data.from_username)
      return
    }
    try {
      const res: any = await api.isMyFriend({
        wechat_id: this.wxId,
        wxids: [data.chatroom_member]
      })
      if (res.list && res.list.length) {
        this.getFriendDetail(res.list[0].username)
      } else {
        this.$weui.alert('该用户不是当且设备的好友')
      }
    } catch (err) {
      console.log(err)

    }
  }

  /**
   * @func 获取点击好友的详情
   * @param wxid 好友字符串id
   */
  private async getFriendDetail(wxid: string) {
    this.$router.push({
      name: 'FriendDesc',
      query: {
        username: wxid
      }
    })
  }

  /**
   * @func 删除消息
   */
  private doDelMsg(data: any, index: number) {
    this.$weui.confirm('确认删除该消息？', () => {
      this.doDelMsgReq(data.id, index)
    }, () => {
      console.log('cancel')
    })
  }

  /**
   * @func 请求删除
   */
  private async doDelMsgReq(msg_id: number, index: number) {
    try {
      await api.hideChatMsg({
        wechat_id: this.wxId,
        chat_msg_id: msg_id
      })
      this.$weui.topTips('删除成功', {
        className: 'success-tips'
      })
      this.list.splice(index, 1)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @func 撤销消息
   */
  private recallMsg(data: any, index: number) {
    this.$weui.confirm('是否撤回该消息？', () => {
      let now = new Date().getTime();
      if (now - data.wx_local_create_time >= 2 * 60 * 1000) {
        this.$weui.topTips('消息发出超2分钟，无法撤回')
        return
      }
      this.doRecallMsg(data.msg_id, index)
    }, () => {
      console.log('cancel')
    })
  }

  /**
   * @func 请求撤回消息
   */
  private async doRecallMsg(msg_id: number, index: number) {

    try {
      await api.doMessageTask({
        wechat_id: this.wxId,
        task: {
          task_time: 0,
          task_single_msg: {
            wechat_id: this.wxId,
            username: this.friendStrId,
            task_type: TaskMsgType.msg_revoke,
            msg_id: msg_id
          }
        }
      })
      this.$weui.topTips('撤回成功', {
        className: 'success-tips'
      })
      this.list.splice(index, 1)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @func 长按头像艾特别人(群里)
   * @param item 当前消息信息
   */
  private toAiTeHim(item: any) {
    if (!this.isChatRoom) return
    if (this.isSelectState) return
    if (this.isSelf(item)) return
    bus.$emit('AiteSomeOne', `@${item.chatroom_member_nick} `)
  }

  private handleTouchStart(e: any, item: any) {
    clearTimeout(this.longPressLoop)
    this.longPressLoop = setTimeout(() => {
      this.toAiTeHim(item)
    },1000)
  }

  private handleTouchEnd() {
    clearTimeout(this.longPressLoop)
  }
}
