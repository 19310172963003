















import {
  Component,
  Prop,
  Vue
} from "vue-property-decorator";

@Component({
  name: 'history-mini-program',
})
export default class HistoryMiniProgram extends Vue {
  @Prop({}) private content!: string;

  private defHead: string = 'https://oss.guojiangmedia.com/wechat/mini-program-head-url.png'

  private get parseContent(): any {
    return JSON.parse(this.content).appmsg;
  }
}
