















import {
  api
} from "@/api";
import {
  ChatModule
} from "@/store/modules/chat";
import {
  WechatModule
} from "@/store/modules/wechat";
import {
  replaceToHttps
} from "@/utils/func";
import {
  WeXMessage
} from "@/utils/interface";
import {
  Component,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";

@Component({
  name: 'history-voice',
})
export default class HistoryVoice extends Vue {
  @Prop({}) private msg!: WeXMessage
  @Prop({}) private robotId!: number

  private active: any = {
    color: '#4A8AED',
  };
  private isPlay: boolean = false;
  private audioDuration: number = 0;
  private defaultAudio: string = 'https://oss.guojiangmedia.com/new/sale/tiger.mp3';
  private isTransfer: boolean = false;
  private voiceMsg: any = null;
  private isleft: boolean = false;

  private get voiceChangeIds(): number[] {
    return ChatModule.voiceChangeIds;
  }

  private get audioPlayId(): number {
    return ChatModule.audioPlayId;
  }

  private get friendStrId(): string {
    return WechatModule.friendStrId
  }

  @Watch('audioPlayId', {
    immediate: true,
    deep: false
  })
  private onChangeAudioPlayId(val: number, oldval: number) {
    if (this.voiceMsg && this.voiceMsg.id !== this.audioPlayId) {
      if (this.$refs.audio) {
        const ref: any = this.$refs.audio;
        ref.currentTime = 0;
        ref.pause();
        this.isPlay = false;
      }
    }
  }

  @Watch('msg', {
    immediate: true,
    deep: true
  })
  private onChangeMsg(val: WeXMessage, oldval: WeXMessage) {
    this.voiceMsg = Object.assign({}, this.msg);
    if (this.voiceMsg.transfer_content) {
      this.voiceMsg.transfer_content = replaceToHttps(this.voiceMsg.transfer_content);
      // this.$nextTick(() => {
      //   const audio: any = document.getElementById(val.id + '_voice')
      //   audio.load()
      //   console.log(audio)
      //   audio.onload = () => {
      //     console.log(audio.duration)
      //   }
      // })
    }
    if (this.voiceMsg.voice_time) {
      this.audioDuration = this.voiceMsg.voice_time / 1000
    }
  }

  @Watch('friendStrId', {
    immediate: true,
  })
  private onChangeFeiendStrId(val: string) {
    this.isleft = val !== this.msg.to_username;
  }

  private doplay() {
    if (!this.voiceMsg.transfer_content) {
      this.doConversion()
      return;
    } else {
      this.play()
    }
  }

  /**
   * @func 转换语音
   * @param none
   */
  private doConversion() {
    if (this.isTransfer) {
      return;
    }
    this.isTransfer = true;
    api.converterVoice({
      wechat_id: this.robotId,
      chat_ids: [this.voiceMsg.id],
    }).then((res: any) => {
      const list = this.voiceChangeIds;
      list.push(this.voiceMsg.id);
      ChatModule.changeVoiceIds(list);
      this.isTransfer = false;
      setTimeout(() => {
        this.doRefresh()
      }, 500)
    });
  }

  /**
   * @func 刷新单条数据
   */
  private doRefresh() {
    if (this.isTransfer) {
      return;
    }
    this.isTransfer = true;
    api.refreshVoice({
      wechat_id: this.robotId,
      id: this.voiceMsg.id,
    }).then((res: any) => {
      this.voiceMsg = res.msg;
      this.$forceUpdate()
      this.isTransfer = false;
      setTimeout(() => {
        this.play()
      }, 500)
    });
  }

  /**
   * @func 获取音频文件 播放
   */
  private play() {
    if (!this.voiceMsg.transfer_content) {
      return;
    }
    ChatModule.changeAudioPlayId(this.voiceMsg.id);

    const refs: any = this.$refs.audio;
    if (refs) {
      refs.play();
      this.isPlay = true;
    }
  }

  /**
   * @func 暂停
   */
  private pause() {
    if (!this.voiceMsg.transfer_content) {
      return;
    }
    console.log('暂停');
    if (this.$refs.audio) {
      const ref: any = this.$refs.audio;
      ref.pause();
      this.isPlay = false;
    }
  }

  /**
   * @func 加载音频
   */
  private ready() {
    // if (this.$refs.audio) {
      const ref: any = this.$refs.audio;
      console.log(ref.duration)
      this.audioDuration = parseInt(ref.duration, 10);
    // }
  }

  /**
   * @func 加载失败
   */
  private error() {
    console.log('播放失败');
  }

  /**
   * @func 播放结束
   */
  private end() {
    this.isPlay = false;
  }
}
