






import {
  Component,
  Prop,
  Vue
} from "vue-property-decorator";

@Component({
  name: 'history-file',
})
export default class HistoryFile extends Vue {
  @Prop({}) private content!: string;

  private get parseContent(): any {
    return JSON.parse(this.content).appmsg;
  }
}
