










import {
  api
} from "@/api"
import {
  WechatModule
} from "@/store/modules/wechat"
import {
  Component,
  Vue,
  Watch
} from "vue-property-decorator"
import ChatHistory from '@/components/chat/history.vue'
import ChatInput from '@/components/chat/chat-input.vue'
import bus from "@/utils/bus"
import {
  ChatModule
} from "@/store/modules/chat"
import {
  MsgType
} from "@/utils/interface"
import {
  appSource
} from "@/utils/func"

@Component({
  name: 'chat',
  components: {
    ChatHistory,
    ChatInput
  }
})
export default class Chat extends Vue {
  private pageNo = 1
  private pageSize = 50
  private hasNextPage = true
  private lastCreateAt = 0
  private wxData: any = []
  private taskId: number[] = []

  private get wxId(): number {
    return WechatModule.wxId
  }

  private get wxStrId(): string {
    return WechatModule.wxStrId
  }

  private get friendInfo(): any {
    return WechatModule.currentFriInfo ? JSON.parse(WechatModule.currentFriInfo) : ''
  }

  private get isChatRoom(): boolean {
    return WechatModule.isChatRoom
  }

  private get friendId(): number {
    return WechatModule.friendId
  }

  private get friendStrId(): string {
    return WechatModule.friendStrId
  }

  private get wxInfo(): any {
    return WechatModule.currentDeviceInfo ? JSON.parse(WechatModule.currentDeviceInfo) : ''
  }

  private get sendMsgTaskId(): number[] {
    return ChatModule.sendMsgTaskId || []
  }

  private mounted() {
    this.pageNo = 1
    this.hasNextPage = true
    this.wxData = []
    this.getMessageList()
    bus.$on('Boardcast', this.getBoardCastData)
  }

  private beforeDestroy() {
    bus.$off('Boardcast')
    bus.$off('AiteSomeOne')
  }

  @Watch('$route')
  private onChangeRoute() {
    bus.$off('Boardcast')
    this.pageNo = 1
    this.hasNextPage = true
    this.wxData = []
    this.getMessageList()
    bus.$on('Boardcast', this.getBoardCastData)
  }

  /**
   * @func 获取发送消息后返回的taskId
   */
  private getTaskId(obj: any) {
    this.taskId.push(obj.id)
  }

  /**
   * @func 获取消息
   */
  private async getMessageList(topDone ? : any) {
    try {
      const res: any = await api.getFriendMsgList({
        friend_type: +this.isChatRoom,
        page_no: this.pageNo,
        page_size: this.pageSize,
        robot_id: this.wxId,
        friend_id: this.friendId,
        wechat_id: this.isChatRoom ? '' : this.friendStrId
      })
      if (!this.wxData || !this.wxData.length) {
        this.lastCreateAt = 0
      }!res.list || res.list.length < this.pageSize ?
        this.hasNextPage = false :
        this.pageNo++;
      if (res.list) {
        for (let i = 0; i < res.list.length; i++) {
          let val: any = res.list[i]
          if (this.lastCreateAt === 0) {
            val.is_show_time = true;
            this.lastCreateAt = val.record_time;
          } else if (this.lastCreateAt - 600 > val.record_time) {
            this.lastCreateAt = val.record_time;
            val.is_show_time = true;
          } else {
            val.is_show_time = false;
          }
          if (!this.hasNextPage && (i === res.list.length - 1)) {
            val.is_show_time = true;
          }
          this.wxData.push(val);
        }
      }
      if (topDone) {
        topDone()
      }
    } catch (err) {
      if (topDone) {
        topDone()
      }
      console.log(err)
    }
  }

  /**
   * @func 监听广播
   */
  private getBoardCastData(data: any) {
    let msg: any = null
    if (data.msg.content) {
      msg = JSON.parse(data.msg.content)
    }
    // if (data.msg.type === 1) {
    //   console.log('msg ===>',msg,  msg.id, msg.chatroom_id, this.friendId)
    // }
    if (msg.wxid !== this.wxId) {
      return
    }

    if (msg && this.taskId.indexOf(msg.task_id) !== -1) {
      this.wxData.map((item: any, index: number) => {
        if (item.suc_task_id === msg.task_id) {
          item.id = msg.chat_id
          item.msg_id = msg.msg_id
          item.wx_local_create_time = msg.wx_local_create_time
          this.$set(this.wxData, index, item)
        }
      })
      let idx = this.taskId.indexOf(msg.task_id)
      this.taskId.splice(idx, 1)
      let ids = this.sendMsgTaskId;
      let index = this.sendMsgTaskId.indexOf(msg.task_id);
      ids.splice(index, 1);
      ChatModule.changeMsgTaskId(ids)
      return;
    }
    if (data.msg.type === 1) {
      if (!msg) return
      if (msg.chatroom_id && msg.chatroom_id === this.friendStrId) {
        let isSelfSend: boolean = false
        if (msg.chatroom_member === this.wxStrId) {
          isSelfSend = true
        }
        const boardData: any = Object.assign({}, {
          chatroom_id: msg.chatroom_id,
          chatroom_member: msg.chatroom_member,
          chatroom_member_icon: msg.chatroom_member_icon,
          chatroom_member_nick: msg.chatroom_member_nick,
          content: msg.content,
          friend_or_chatroom_wechat_id: msg.chatroom_id,
          chatroom_member_remark: msg.chatroom_member_remark,
          id: msg.chat_id,
          msg_type: msg.msg_type,
          record_time: msg.record_time,
          wechat_id: msg.wxid
        })
        this.wxData.unshift(boardData);
        this.clearRedPoint(msg.wxid, msg.chatroom_id)
      } else if (this.friendId && msg.id === this.friendId) {
        // else if (msg.from_username === this.friendStrId || msg.to_username === this.friendStrId)
        let isSelfSend: boolean = true;
        if (msg.from_username === this.friendStrId) {
          isSelfSend = false;
        }
        const boardData: any = Object.assign({}, {
          wechat_id: 0,
          friend_or_chatroom_wechat_id: this.wxStrId,
          from_username: msg.from_username.toString(),
          to_username: msg.to_username.toString(),
          from_username_nick_name: isSelfSend ? this.wxInfo.name : this.friendInfo.nick_name,
          from_username_head_img: isSelfSend ? this.wxInfo.avatar : (this.friendInfo.head_url || this
            .friendInfo.avatar),
          to_username_nick_name: isSelfSend ? this.friendInfo.nick_name : this.wxInfo.name,
          to_username_head_img: isSelfSend ? this.friendInfo.head_url : this.wxInfo.avatar,
          msg_type: msg.msg_type,
          content: msg.content,
          record_time: msg.record_time,
          id: msg.chat_id || 0
        });
        this.wxData.unshift(boardData);
        this.clearRedPoint(msg.wxid, msg.wx_src_id)
      }
    }
  }

  /**
   * @func 消除红点
   * @param 设备id
   * @Param 好友字符串id
   */
  private async clearRedPoint(wxid: number, wxSrcId: string) {
    try {
      await api.friendTask({
        robot: wxid,
        task: {
          task_time: parseInt(new Date().getTime() / 1000 + '', 10),
          task_friends: {
            task_type: 8,
            clear_red_dot: {
              wxid: wxSrcId
            }
          }
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  // 发送消息
  private getSendMsg(data: any) {
    const sendData: any = data.msg;
    const msg: any = {
      id: new Date().getTime(),
      wechat_id: sendData.wechat_id,
      friend_or_chatroom_wechat_id: this.wxStrId,
      from_username: sendData.from_username,
      to_username: sendData.to_username,
      from_username_nick_name: sendData.from_nick_name,
      from_username_head_img: sendData.from_avatar,
      to_username_nick_name: sendData.to_nick_name,
      to_username_head_img: sendData.to_avatar,
      msg_type: sendData.msg_type,
      content: sendData.content,
      record_time: sendData.record_time,
      is_show_time: false,
      transfer_content: sendData.msg_type === MsgType.msg_type_voice ? sendData.content : '',
      voice_time: sendData.voice_time,
      suc_task_id: sendData.suc_task_id,
    };
    if (sendData.msg_type === 3) {
      msg.transfer_content = sendData.content
    }

    // 判断当前时间与最后一条消息时间是否
    let lastData: any = this.wxData[0];
    if (msg.record_time - 600 > lastData.record_time) {
      msg.is_show_time = true;
    }
    this.wxData.unshift(msg);
    this.$nextTick(() => {
      if (appSource() === 'ios') {
        let scrollBox: any = document.getElementById('scrollBox')
        scrollBox.scrollTop = 0
      } else if (appSource() === 'andriod') {
        let scrollBox: any = document.getElementById('msg_end')
        scrollBox.scrollIntoView()
      }
    })
  }

}
